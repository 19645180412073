import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image, TrailguidePlugin } from "../../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`There are only beautiful ways to access the Val Müstair. Driving over the
Ofenpass, the whole valley lies infront of you, with the massive Ortler right
ahead. From Italy you have two possibilities, driving up from Bormio over the
Umbrail pass, surrounded by impressive mountains, or coming out of the
beautiful Vinschgau valley. No matter which way you choose, you will always
end up in the quiet valley of Müstair in a 'forgotten' part of Switzerland.`}</p>
    <TrailguidePlugin content="lat=46.6181&lng=10.4046&coverage=8.7" mdxType="TrailguidePlugin" />
    <p className="text-xs mb-16" align="center">
      <p>{`Open these trails directly in the `}<a parentName="p" {...{
          "href": "https://trailguide.net/?pos=12,46.6181,10.4046"
        }}>{`web-app`}</a></p>
    </p>
    <p>{`Nevertheless, the valley offers good touristic infrastructure, so you find
everything you need, from an accomodation, bike shop, you can hire guides,
shuttles and also the famous Postbus takes you and your bike up to the mountain.`}</p>
    <br />
    <br />
    <h4>{`Piz Umbrail`}</h4>
    <p>{`Let's start with one of the most impressive descents we have ever done. The
view from the top is unbelievable. In the backround you see the Piz Palü and
Piz Bernina with the famous Bianco ridge.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/3791"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/valmustair_21.jpg" mdxType="Image" />
    <br />
    <p>{`With 3033m The Piz Umbrail is the highest peak you can reach from the Val
Müstair. From the Umbrailpass you need to carry your bike to the peak.
Sometimes on a difficult exposed hiking path.`}</p>
    <Image src="destinations/valmustair_20.jpg" mdxType="Image" />
    <br />
    <p>{`It is probably quite hard to find such a high peak with such a flowy and
easy trail. Nevertheless, don't take the easy too easy. The loose gravel
and some switchbacks in steeper terrain, make it challenging here and there.`}</p>
    <Image src="destinations/valmustair_22.jpg" mdxType="Image" />
    <br />
    <p>{`The descent is very versatile and the landscape, especially around the Lago
di Rims, stunning.`}</p>
    <Image src="destinations/valmustair_23.jpg" mdxType="Image" />
    <br />
    <h4>{`Uphill`}</h4>
    <p>{`But there are a lot of other possibilties as well. Typical for Switzerland
is the famous Postbus. You can reach the Ofenpass and even the Umbrailpass.
Bringing your bike requires a reservation by phone before.`}</p>
    <Image src="destinations/valmustair_1.jpg" mdxType="Image" />
    <br />
    <h4>{`Buffalora climb`}</h4>
    <p>{`From the Ofenpass you can start several tours in every direction.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/3777"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/valmustair_7.jpg" mdxType="Image" />
    <br />
    <h4>{`Passo Gallo`}</h4>
    <p>{`A big tour for example, that leads over the Passo Gallo down tho the Lago di Livigno.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/3778"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/valmustair_10.jpg" mdxType="Image" />
    <br />
    <h4>{`Chazfora`}</h4>
    <p>{`In the beginning the route is similar to some famous trans-alp trails, but
then offers some hidden gems.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/3780"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/valmustair_11.jpg" mdxType="Image" />
    <br />
    <p>{`The alpine terrain offers some unexpected flow. The colors are beautiful,
especially in fall, which is also the best season for trails in this altitude.`}</p>
    <Image src="destinations/valmustair_12.jpg" mdxType="Image" />
    <br />
    <h4>{`Stelvio Pass`}</h4>
    <p>{`Not far from Müstair, is the Passo dello Stelvio. From the second highest
pass - you can reach with a car - many descents start infront of the
impressive Ortler.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/3790"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/valmustair_18.jpg" mdxType="Image" />
    <br />
    <h4>{`Tibet Trail`}</h4>
    <p>{`If you like switchbacks, this trail is for you! The Stelvio Pass is easily
accessible by shuttle. From there a long but challenging descent awaits you.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/3782"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/valmustair_15.jpg" mdxType="Image" />
    <br />
    <h4>{`Werners Käsetrail`}</h4>
    <p>{`On the south faced mountain side of the valley, you will find some really
nice flowy trails that coast along the mountain on different altitude levels.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/3769"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/valmustair_6.jpg" mdxType="Image" />
    <br />
    <p>{`If you are nice, Werner even shows you how he makes his really tasty cheese.`}</p>
    <Image src="destinations/valmustair_4.jpg" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      